
import { defineComponent, ref } from 'vue'
import ApplicationPop from '@/components/ApplicationPop/index.vue'
export default defineComponent({
  name: '',
  components: {
    ApplicationPop
  },
  setup(props: any) {
    const dialogVisible = ref(false)
    const showDialog = (value: boolean) => {
      dialogVisible.value = value
    }
    return {
      dialogVisible,
      showDialog
    }
  }
})
