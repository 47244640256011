export const allData = {
    intro2: [
        {
            name: '工作门户',
            content:
                '建立支持多级用户（省、市、县、乡）的个人工作门户。提供习近平法治思想、全面依法治省政策法规、全面依法治省工作情况、素质提升教育培训等业务内容展示，实现各业务模块之间的无缝衔接'
        },
        {
            name: '统筹协调管理',
            content:
                '为督办工作联动管理提供支撑，确保依法治省办实时掌握依法治省各项任务落实的进度，汇总分析问题及改进措施，保障任务落地，及时全面掌握依法治省工作进展完成情况'
        },
        {
            name: '法治督察管理',
            content:
                '对法治建设责任落实情况进行全面督察管理，通过法治工作重要决定和方案备案管理、法治政府建设示范创建管理，落实科学立法、严格执法、公正司法、全民守法四个方面'
        },
        {
            name: '法治调研管理',
            content:
                '面向调研任务发起单位、课题申报单位和课题评审专家，实现对法治调研课题的报送、评审、发布等全流程课题管理'
        },
        {
            name: '法治人才库管理',
            content:
                '法治人才库管理主要实现对全省（市）法治建设机构、人员和法治专家信息的管理，实现对法治机构、人员和法治专家信息的掌握和统计分析'
        },
        {
            name: '政府法律顾问工作管理',
            content:
                '实现全面掌握全省（市）政府法律顾问工作机构和工作队伍情况以及政府法律顾问业务办理情况，提供对政府法律顾问工作机构、工作队伍情况、业务办理数据统计和分析'
        },
        {
            name: '运行监测体系',
            content:
                '利用工作平台产生的业务数据以及自动采集汇聚的各执法单位行政执法主题库数据，从习近平法治思想学习贯彻、法治建设组织领导、地方立法、法治政府、公正司法、全民守法六个方面进行依法治省（市）动态运行数据的监测'
        },
        {
            name: '决策支撑体系',
            content:
                '依据对动态运行监测数据分析，从习近平法治思想学习贯彻、法治建设组织领导、地方立法、法治政府、公正司法、全民守法六个方面对关键数据分析，实现对依法治省各项任务开展情况的全面掌握，为法治决策提供辅助支撑'
        },
    ],
    features: [
        {
            name: '畅通信息交流渠道',
            content:
                '建立纵横向联通机制，畅通信息交流沟通渠道。实现横向与依法治省（市）办同协调小组及成员单位，纵向与依法治市（县）办互联互通'
        },
        {
            name: '督办任务上传下达',
            content:
                '建立依法治省（市）统筹协调工作机制，实现依法治省（市）业条在线办理，为调查研究、任务督办、法治督察、法治备案、资料管理等督察督办工作提供信息化支撑'
        },
        {
            name: '考核评估依法决策',
            content:
                '实现依法治省（市）在线考核评估，汇集立、执、司、守等多方面的数据，建立分析指标与模型，进行大数据分析，直观展示依法治省（市）各方面情况，支撑领导科学决策'
        },
        {
            name: '立执司守统筹协同',
            content:
                '通过“挂图作战”，全面统筹依法治省（市）各项工作任务，实现一图统览法治政府建设情况，推进科学立法、严格执法、公正司法、全民守法'
        }
    ],
    cases: [
        {
            img: 'GovernByLaw-case1.png',
            name: '广东省依法治省一体化平台',
            content:
                '广东省依法治省一体化平台建设了工作门户、统筹协调管理、粤港澳大湾区法治管理、法治人才库、法治政府建设示范创建管理、政府法律顾问工作管理等功能，构建了依法治省运行监测体系、评价指标体系、决策支撑体系。有效督促、指导、评估依法治省各项任务顺利开展，保障各项任务有效落地，为全面提升依法治省工作治理体系和治理能力现代化水平提供技术支撑，为全面提高法治广东、法治政府、法治社会建设提供智能支持'
        },
        {
            img: 'GovernByLaw-case2.png',
            name: '山东全面依法治省信息平台',
            content:
                '山东全面依法治省信息平台建设工作平台、信息报送、动态监测、数据汇聚、决策支持等系统，实现对依法治省各项任务开展情况的全面掌握，为法治工作提供数据依据，助力发现全省法治建设的堵点、难点，切实发挥省委依法治省办统筹、协调、督促、检查、推动法治政府考核的工作职责，从而形成全面依法治省工作一盘棋新格局'
        },
        {
            img: 'GovernByLaw-case3.png',
            name: '黑龙江省法治建设督察管理平台',
            content:
                '法治建设督察平台以业务需求为导向，以数据为核心，建设法治督察、法治线索、数据分析等系统，实现“聚焦关键少数、紧盯执行落实、实施常态督察、整改突出问题”，切实减少实地督察与考评频次，让数据多跑路、人员少跑腿，真正做到背靠背督察、不见面督察。助力全省依法治省督察工作实时运行，推进法治督察工作精准化、常态化、全量化'
        }
    ]
}
