// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/GovernByLaw/title-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header-cont[data-v-3a9efcc0] {\n  width: 100%;\n  height: 697px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: 100% 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  animation: breath-3a9efcc0 4s infinite;\n}\n@keyframes breath-3a9efcc0 {\n50% {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center / 104% 102%;\n}\n100% {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center / 100% 100%;\n}\n}\n.header-title[data-v-3a9efcc0] {\n  font-size: 72px;\n  color: #ffffff;\n  letter-spacing: 10px;\n  z-index: 99;\n}\n.header-tip[data-v-3a9efcc0] {\n  font-size: 20px;\n  color: #ffffff;\n  margin: 38px 0;\n  letter-spacing: 1px;\n  z-index: 99;\n}\n.bottomBtn[data-v-3a9efcc0] {\n  width: 236px;\n  height: 65px;\n  line-height: 65px;\n  text-align: center;\n  border-radius: 32px;\n  background: #695ffc;\n  background: linear-gradient(to right, #2778ff 0%, #2d95ff 100%);\n  font-size: 26px;\n  color: #ffffff;\n  z-index: 99;\n}\n", ""]);
// Exports
module.exports = exports;
