// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/GovernByLaw/part3.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".common-cont[data-v-ef9b3eba] {\n  background: #fff;\n}\n.part2[data-v-ef9b3eba] {\n  padding-bottom: 20px;\n}\n.part3[data-v-ef9b3eba] {\n  width: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top / 100% 75%;\n}\n.part3[data-v-ef9b3eba] .title-box {\n  color: #fff;\n}\n.part3[data-v-ef9b3eba] .title-box:after {\n  background: #E99825;\n}\n", ""]);
// Exports
module.exports = exports;
