
import { defineComponent, ref } from 'vue'
import { IComObj } from '@/global/types'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js'
import { EffectFade, Autoplay, Pagination } from 'swiper'
export default defineComponent({
  props: {
    data2: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup(props: any) {
    const color2 = ['#74CC4A','#3677FC','#E99825','#6D47E8','#E99825','#3677FC','#74CC4A','#6D47E8',]
    const imgs2 = ['top-icon1.png', 'top-icon2.png','top-icon3.png', 'top-icon4.png', 'top-icon5.png','top-icon6.png','top-icon7.png','top-icon8.png']
    const innerData2 = ref<IComObj[]>([])
    innerData2.value = props.data2.map((item: any, index: number) => {
      item.img = require('@/assets/images/GovernByLaw/' +imgs2[index])
      item.color = color2[index]
      return item
    })
    return {
      innerData2,
      modules: [EffectFade, Autoplay, Pagination]
    }
  }
})
